import React from 'react';

import "./EsgTrackingCarChart.css";
import { Divider } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';

    const EsgTrackingCarChart = ({ totalArea, carFeatures }) => {

    const datesMock = {
        "GO-5217104-F4E1505104E94C0991CAABB9D1BAB367": {
            subscription: "22/06/2016",
            update: "--"
        },
        "GO-5217104-68B2E20505384772A8DDD9A47F2BB61F": {
            subscription: "06/05/2015",
            update: "--"
        },
        "GO-5217104-118D6D71BEB746878331DB38BA8B49B2": {
            subscription: "21/03/2016",
            update: "--"
        },
        "GO-5222005-03A4E6EFDD5342A68E825CB3350F857F": {
            subscription: "06/03/2015",
            update: "09/09/2019"
        },
        "GO-5219209-08D53F147CFD4409800B0988FBD801A5": {
            subscription: "23/02/2015",
            update: "--"
        },
        "GO-5219209-AD1626925F9A40E09DFE5568773587DC": {
            subscription: "18/03/2019",
            update: "--"
        },
        "GO-5219209-74D1247117EA4B5FBE2421F8A02AB78B": {
            subscription: "06/12/2018",
            update: "03/04/2024"
        },
        "GO-5219209-23CFCC73BE074CAAA91D17969EC4AA35": {
            subscription: "10/12/2018",
            update: "18/06/2024"
        },
        "GO-5222005-220540AD6D214E88AB7B4B707EAE9B02": {
            subscription: "03/03/2015",
            update: "--"
        }
    }
    
    

    let carCodes = carFeatures.map(
        (feature) => feature.cod_car
    );
    
    let carCode = "";
    if(carCodes.length === 1) carCode = carCodes[0];

    
    return (
        <div
            className="esgTrackingCarChartCard"
        >
            <div className='esgTrackingCarChartTitle'>Rastreio da área</div>
            <div className='esgTrackingCarChartContent'>
                {carCodes.length > 1 &&
                    <div className='esgTrackingCarChartDescription'>
                        Área total dos imóveis
                    </div>
                }
                <div className="esgTrackingCarChartStatus">
                    {`${(totalArea).toFixed(2).replace('.', ',')} Ha`}
                </div>
                <Divider style={{ margin: 8 }}/>
                {carCodes.length === 1 &&
                    <div className="esgTrackingCarChartDescription">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            Data de inscrição: 
                            <div>
                                {datesMock[carCode]?.subscription || "Indisponível"}
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            Data da última retificação: 
                            <div>
                                {datesMock[carCode]?.update || "Indisponível"}
                            </div>
                        </div>
                    </div>
                }
                {carCodes.length > 1 && (
                    <div className="esgTrackingCarChartCarCodes">
                        {carCodes.map((code, index) => (
                            <div key={index}>
                                {code}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EsgTrackingCarChart;
