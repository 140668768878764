import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  Result,
  Select,
  Upload,
  message,
  Divider,
  Typography,
  Checkbox,
  notification,
} from "antd";
import {
  FileImageOutlined,
  PaperClipOutlined,
  SendOutlined,
  UploadOutlined,
  InboxOutlined,
  EditOutlined,
} from "@ant-design/icons";
import StepTracker from "../components/dashboard/StepTracker";
import { validateFilesAPI, createTicketsAPI } from "../components/api/api";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import "./KmlUpload.css";

function KmlUpload() {
  const [timeoutError, setTimeoutError] = useState(false);
  const errorShownRef = useRef(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formDataList, setFormDataList] = useState([]);
  const [formFileList, setFormFileList] = useState([]);
  const [generalInfo, setGeneralInfo] = useState({
    producerName: "",
    farmName: "",
    cpf: "",
  });

  const clearSessionStorageItems = () => {
    sessionStorage.removeItem("cachedHistory");
    sessionStorage.removeItem("cachedTickets");
    sessionStorage.removeItem("cachedTicketsHasMore");
    sessionStorage.removeItem("cachedTicketsPage");
  };

  const navigate = useNavigate();

  const nextStepSubmit = async () => {
    try {
      if (currentStep === 2) {
        if (!formFileList.length) {
          message.error("Nenhum arquivo selecionado para análise.");
          return;
        }

        console.log("Iniciando submissão para análise...");
        await handleSubmitForAnalysis();

        sessionStorage.clear();
        localStorage.clear();
        clearSessionStorageItems();

        console.log("Submissão concluída e cache limpo.");
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Erro durante a execução do próximo passo:", error);
      message.error("Ocorreu um erro ao tentar avançar. Tente novamente.");
    }
  };

  const nextStep = async () => {
    if (currentStep === 1) {
        try {
            setLoading(true);

            const filesToValidate = formFileList.map((file) => file.originFileObj);

            if (!filesToValidate.length) {
                notification.warning({
                    message: "Nenhum Arquivo",
                    description: "Por favor, adicione arquivos antes de prosseguir.",
                    placement: "bottomRight",
                });
                setLoading(false);
                return;
            }

            let response;
            try {
                response = await validateFilesAPI(filesToValidate);
            } catch (error) {
                console.error("Erro na validação de arquivos:", error);
                notification.error({
                    message: "Erro na Validação de Arquivos",
                    description: "Não foi possível validar os arquivos. Tente novamente.",
                    placement: "bottomRight",
                    duration: 10,
                });
                setLoading(false);
                return;
            }

            const { valid_geometries = [], invalid_geometries = [], invalid_kmls = [] } = response;

            if (invalid_kmls.length > 0 || invalid_geometries.length > 0) {
                const invalidFileNames = [
                    ...invalid_kmls,
                    ...invalid_geometries.map((geom) => geom.file_name),
                ];

                notification.error({
                    message: "Arquivos Inválidos",
                    description: `Os seguintes arquivos foram descartados:\n${invalidFileNames.join(", ")}`,
                    placement: "bottomRight",
                    duration: 10,
                });

                setFormFileList((prev) =>
                    prev.filter((file) => !invalidFileNames.includes(file.name))
                );
                setFormDataList((prev) =>
                    prev.filter((data) => !invalidFileNames.includes(data.fileName))
                );
            }

            if (!valid_geometries.length) {
                notification.warning({
                    message: "Nenhum Arquivo Válido",
                    description: "Por favor, adicione arquivos válidos para continuar.",
                    placement: "bottomRight",
                });
                setLoading(false);
                return;
            }

            const fileCounter = {};
            const updatedFormDataList = [];
            const updatedFileList = [];

            valid_geometries.forEach((geometry, geomIndex) => {
                let baseFileName = geometry.file_name;

                // Remove números do início do nome do arquivo
                baseFileName = baseFileName.replace(/^\d+-/, "");

                if (!fileCounter[baseFileName]) {
                    fileCounter[baseFileName] = 0;
                }

                const geometries =
                    geometry.geom.type === "MultiPolygon"
                        ? geometry.geom.coordinates.map((coords) => ({
                            type: "Polygon",
                            coordinates: coords,
                        }))
                        : [geometry.geom];

                geometries.forEach((geom, index) => {
                    const isDuplicate = fileCounter[baseFileName] > 0; // Identifica duplicatas
                    const uniqueFileName = isDuplicate
                        ? `${baseFileName} (${fileCounter[baseFileName] + 1})`
                        : baseFileName;

                    updatedFormDataList.push({
                        originalFileName: geometry.file_name, // Nome original do arquivo
                        fieldName: uniqueFileName,
                        farmerName: "",
                        fileName: uniqueFileName,
                        geometry: geom,
                        cropType: "",
                        soilType: "",
                        cycleType: "",
                        proposalId: generalInfo.producerName,
                        serviceType: "",
                    });

                    updatedFileList.push({
                        name: uniqueFileName,
                        uid: `${baseFileName}-${geomIndex}-${index}-${Math.random()}`,
                    });

                    fileCounter[baseFileName]++;
                });
            });

            setFormDataList(updatedFormDataList);
            setFormFileList(updatedFileList);

            notification.success({
                message: "Validação Bem-Sucedida",
                description: "Todos os arquivos foram validados com sucesso.",
                placement: "bottomRight",
                duration: 2,
            });

            setCurrentStep(2);
        } finally {
            setLoading(false);
        }
    }
};



  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    window.location.reload();
  };
  const renderItem = (originNode, file, fileList, actions) => {
    const fileGeometries = formDataList.filter(
      (formData) => formData.fileName === file.name
    );

    if (!fileGeometries.length) {
      return (
        <div key={file.uid}>
          <p>Erro: Nenhum dado encontrado para o arquivo {file.name}</p>
        </div>
      );
    }

    return (
      <div className="kmlFileRender" key={file.uid}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <FileImageOutlined style={{ fontSize: "32px" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            {fileGeometries.map((formData, index) => (
              <div
                key={`${file.uid}-${index}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <div>
                  <Typography.Title
                    editable={{
                      onChange: (value) =>
                        handleFormDataChange(
                          { target: { name: "fieldName", value } },
                          formDataList.indexOf(formData)
                        ),
                      icon: <EditOutlined style={{ color: "#B64CFF" }} />,
                    }}
                    level={5}
                    style={{ margin: 0 }}
                  >
                    {formData.fieldName || ""}
                  </Typography.Title>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <Form.Item required>
                      <Select
                        className="cropTypeSelector"
                        onChange={(value) =>
                          handleSelectChange(
                            value,
                            "cropType",
                            formDataList.indexOf(formData)
                          )
                        }
                        value={formData.cropType || null}
                        size="small"
                        placeholder="Cultura"
                        disabled={["vision"].includes(formData.serviceType)}
                      >
                        <Select.Option value="soja">Soja</Select.Option>
                        <Select.Option value="milho">Milho</Select.Option>
                        <Select.Option value="arroz">
                          Arroz irrigado
                        </Select.Option>
                        <Select.Option value="feijao1">
                          Feijão 1ª safra
                        </Select.Option>
                        <Select.Option value="feijao2">
                          Feijão 2ª safra
                        </Select.Option>
                        <Select.Option value="trigo" disabled>
                          Trigo
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item required>
                      <Select
                        className="soilTypeSelector"
                        onChange={(value) =>
                          handleSelectChange(
                            value,
                            "soilType",
                            formDataList.indexOf(formData)
                          )
                        }
                        value={formData.soilType || null}
                        size="small"
                        placeholder="Tipo do solo"
                        disabled={["vision"].includes(formData.serviceType)}
                      >
                        <Select.Option value="1"> Solo AD 1 </Select.Option>
                        <Select.Option value="2"> Solo AD 2 </Select.Option>
                        <Select.Option value="3"> Solo AD 3 </Select.Option>
                        <Select.Option value="4"> Solo AD 4 </Select.Option>
                        <Select.Option value="5"> Solo AD 5 </Select.Option>
                        <Select.Option value="6"> Solo AD 6 </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item required>
                      <Select
                        className="cycleTypeSelector"
                        onChange={(value) =>
                          handleSelectChange(
                            value,
                            "cycleType",
                            formDataList.indexOf(formData)
                          )
                        }
                        value={formData.cycleType || null}
                        size="small"
                        placeholder="Tipo de ciclo"
                        disabled={["vision", "sense"].includes(
                          formData.serviceType
                        )}
                      >
                        <Select.Option value="1">Precoce</Select.Option>
                        <Select.Option value="2">Longo</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      required
                      style={{ marginBottom: "0px", display: "none" }}
                    >
                      <Input
                        type="text"
                        className="proposalIdInput"
                        name="proposalId"
                        value={formData.proposalId || ""}
                        onChange={(e) =>
                          handleFormDataChange(
                            e,
                            formDataList.indexOf(formData)
                          )
                        }
                        size="small"
                        placeholder="ID da Proposta"
                        required
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Form.Item required style={{ marginBottom: "0px" }}>
              <Select
                className="serviceSelector"
                onChange={(value) =>
                  handleSelectChange(
                    value,
                    "serviceType",
                    formDataList.indexOf(fileGeometries[0])
                  )
                }
                value={fileGeometries[0]?.serviceType || null}
                size="small"
                placeholder="Serviço"
              >
                {!["arroz", "trigo", "feijao1", "feijao2"].includes(
                  fileGeometries[0]?.cropType
                ) && <Select.Option value="aura">Aura</Select.Option>}
                <Select.Option value="sense">Sense</Select.Option>
                <Select.Option value="vision">Vision</Select.Option>
              </Select>
            </Form.Item>
            <Button
              className="removeFileButton"
              type="link"
              onClick={() => handleRemoveFile(file)}
            >
              Remover
            </Button>
          </div>
          <div className="uploadFileName">
            <PaperClipOutlined />
            {file.name}
          </div>
        </div>
      </div>
    );
  };

  const handleRemoveFile = (file) => {
    const updatedFileList = formFileList.filter((f) => f.uid !== file.uid);
    const updatedFormDataList = formDataList.filter(
      (_, index) => formFileList.indexOf(file) !== index
    );

    if (updatedFormDataList.length < 50) {
      errorShownRef.current = false;
    }

    setFormFileList(updatedFileList);
    setFormDataList(updatedFormDataList);
    clearSessionStorageItems();
  };

  const isUploadButtonDisabled = () => {
    if (!formFileList.length) {
      return true;
    }

    return formDataList.some((formData) => {
      const {
        fieldName,
        cropType,
        soilType,
        cycleType,
        proposalId,
        serviceType,
      } = formData;
      if (serviceType === "aura") {
        return (
          !fieldName || !cropType || !soilType || !cycleType || !proposalId
        );
      }

      if (serviceType === "sense") {
        return !fieldName || !cropType || !soilType || !proposalId;
      }

      if (serviceType === "vision") {
        return !fieldName || !proposalId;
      }

      return true;
    });
  };

  const handleSubmitForAnalysis = async () => {
    setLoading(true);
    const timeout = new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), 45000)
    );
    try {
      const submitData = formDataList.map((formData, index) => ({
        ...generalInfo,
        ...formData,
        file: formFileList[index] ? formFileList[index].originFileObj : null,
      }));
      await Promise.race([createTicketsAPI(submitData), timeout]);
      setCurrentStep(3);
    } catch (error) {
      console.error("Error submitting for analysis:", error);
      if (error.message === "Request timed out") {
        setTimeoutError(true);
        message.error(
          "Erro ao enviar para análise. Tempo de solicitação esgotado."
        );
      } else if (error.message === "Failed to fetch") {
        message.error(
          "Erro ao enviar para análise. Falha na conexão com o servidor."
        );
        window.location.reload();
      } else if (error.responseData) {
        if (error.responseData.invalid_kmls) {
          message.error({
            content: `Falha ao enviar para análise. Remova o(s) arquivo(s) para efetuar um novo envio. Arquivos inválidos: ${error.responseData.invalid_kmls.join(
              ", "
            )}.`,
            duration: 5,
          });
        }
      } else {
        message.error(
          "Erro ao enviar para análise. Nenhum dado de resposta fornecido pelo servidor."
        );
      }
      setLoading(false);
    } finally {
      clearSessionStorageItems();
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormDataList([]);
    setFormFileList([]);
    setGeneralInfo({
      producerName: "",
      farmName: "",
      cpf: "",
    });
    setCurrentStep(1);
  };

  const handleFormDataChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFormDataList = [...formDataList];
    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [name]: value,
    };
    setFormDataList(updatedFormDataList);
  };

  const handleSelectChange = (value, option, index) => {
    const updatedFormDataList = [...formDataList];

    updatedFormDataList[index] = {
      ...updatedFormDataList[index],
      [option]: value,
    };

    if (
      option === "cropType" &&
      ["arroz", "feijao1", "feijao2", "trigo"].includes(value)
    ) {
      if (updatedFormDataList[index].serviceType === "aura") {
        updatedFormDataList[index].serviceType = "";
      }
    }

    if (option === "serviceType" && value === "vision") {
      updatedFormDataList[index].cropType = "";
      updatedFormDataList[index].soilType = "";
      updatedFormDataList[index].cycleType = "";
    }

    if (option === "serviceType" && value === "sense") {
      updatedFormDataList[index].cycleType = "";
    }

    const optionMap = {
      cropType: "option1",
      serviceType: "option2",
      cycleType: "option3",
      soilType: "option4",
    };

    const checkboxOption = optionMap[option];

    if (checkboxOption) {
      if (index === 0 && selectedValues.includes(checkboxOption)) {
        console.log(
          `Sincronizando campos com base no índice 0 para a opção ${checkboxOption}.`
        );

        updatedFormDataList.forEach((item, idx) => {
          if (idx !== 0) {
            item[option] = value;
          }
        });
      }

      if (index !== 0 && selectedValues.includes(checkboxOption)) {
        console.log(
          `Desmarcando checkbox ${checkboxOption} devido à edição manual no índice ${index}.`
        );

        setSelectedValues((prev) =>
          prev.filter((selected) => selected !== checkboxOption)
        );
      }
    }

    setFormDataList(updatedFormDataList);
  };

  const onFileChange = ({ fileList }) => {
    const exceededFiles = fileList.slice(50);
    const exceededFileNames = exceededFiles.map((file) => file.name).join(", ");

    if (fileList.length > 50 && !errorShownRef.current) {
      message.error({
        content: `Você pode fazer upload de no máximo 50 arquivos. Arquivos removidos: ${exceededFileNames} `,
        duration: 4,
      });
      errorShownRef.current = true;
    }

    if (fileList.length < 50) {
      errorShownRef.current = false;
    }

    fileList = fileList.slice(0, 50);

    const updatedFormDataList = [...formDataList];

    fileList.forEach((file, index) => {
      if (!updatedFormDataList[index]) {
        updatedFormDataList[index] = {
          fieldName: "",
          farmName: "",
          cropType: "",
          soilType: "",
          cycleType: "",
          proposalId: "",
          serviceType: "",
        };
      }
    });

    setFormDataList(updatedFormDataList);
    setFormFileList(fileList);
  };

  const handleCheckboxChange = (option) => {
    if (selectedValues.includes(option)) {
      handleCheckboxUncheck(option); 
    } else {
      handleCheckboxCheck(option); 
    }
  };

  const handleCheckboxCheck = (option) => {
    console.log(`Sincronizando todos os campos para a opção ${option}`);
    const updatedFormData = [...formDataList];

    switch (option) {
      case "option1": // Cultura
        const currentCropType = updatedFormData[0]?.cropType || null;
        updatedFormData.forEach((item, idx) => {
          if (idx !== 0) item.cropType = currentCropType;
        });
        break;
      case "option2": // Serviço
        const currentServiceType = updatedFormData[0]?.serviceType || null;
        updatedFormData.forEach((item, idx) => {
          if (idx !== 0) item.serviceType = currentServiceType;
        });
        break;
      case "option3": // Ciclo
        const currentCycleType = updatedFormData[0]?.cycleType || null;
        updatedFormData.forEach((item, idx) => {
          if (idx !== 0) item.cycleType = currentCycleType;
        });
        break;
      case "option4": // Solo
        const currentSoilType = updatedFormData[0]?.soilType || null;
        updatedFormData.forEach((item, idx) => {
          if (idx !== 0) item.soilType = currentSoilType;
        });
        break;
      default:
        console.error("Opção desconhecida:", option);
    }

    setFormDataList(updatedFormData);
    setSelectedValues((prev) => [...prev, option]);
  };

  const handleCheckboxUncheck = (option) => {
    console.log(`Permitir edição manual para a opção ${option}`);
    setSelectedValues((prev) => prev.filter((value) => value !== option)); 
  };

  const [selectedValues, setSelectedValues] = useState([]);

  const onChange = (checkedValues) => {
    if (!Array.isArray(checkedValues)) {
      console.error("checkedValues não é um array:", checkedValues);
      return;
    }

    setSelectedValues(checkedValues);
    console.log("Valores Selecionados:", checkedValues);
  };

  const beforeUpload = () => false;

  const handleCSVUpload = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const csvData = results.data.reduce((acc, row) => {
          const fileName = row.fileName?.trim().toLowerCase();
          if (fileName) {
            acc[fileName] = {
              fieldName: row.fieldName?.trim() || "",
              cropType: mapCropType(row.cropType),
              cycleType: mapCycleType(row.cycleType),
              soilType: mapSoilType(row.soilType),
              proposalId: row.proposalId?.trim() || "",
              serviceType: row.service?.toLowerCase() || "",
            };
          }
          return acc;
        }, {});

        setFormDataList((prevFormDataList) =>
          prevFormDataList.map((item) => {
            const trimmedFileName = item.fileName.trim().toLowerCase();
            return {
              ...item,
              ...(csvData[trimmedFileName] || {}),
            };
          })
        );

        message.success("CSV carregado com sucesso e campos atualizados.");
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        message.error("Erro ao carregar o CSV");
      },
    });

    return false;
  };

  const mapCropType = (crop) => {
    const validCrops = [
      "soja",
      "trigo",
      "feijao1",
      "feijao2",
      "milho",
      "arroz",
      "laudo",
    ];
    const lowerCaseCrop = crop.toLowerCase();
    const normalizedCrop = lowerCaseCrop
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return validCrops.includes(normalizedCrop) ? normalizedCrop : null;
  };

  const mapCycleType = (cycle) => {
    const cycleMap = {
      precoce: "1",
      longo: "2",
      1: "1",
      2: "2",
    };

    return cycleMap[cycle.toLowerCase()] || null;
  };

  const mapSoilType = (soil) => {
    const soilMap = {
      "solo tipo 1": "1",
      "solo tipo 2": "2",
      "solo tipo 3": "3",
      "solo ad 1": "1",
      "solo ad 2": "2",
      "solo ad 3": "3",
      "solo ad 4": "4",
      "solo ad 5": "5",
      "solo ad 6": "6",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
    };

    return soilMap[soil.toLowerCase()] || null;
  };

  useEffect(() => {
    clearSessionStorageItems();
  }, []);

  return (
    <div className="kmlUploadForm">
      <div className="kmlUploadHeader">
        <p>Nova consulta</p>
        <Divider className="customDivider" />
      </div>
      <div className="kmlUploadCard">
        <StepTracker currentStep={currentStep} />
        <Form layout="vertical" className="formContainer">
          {currentStep === 1 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  flexDirection: "column",
                  width: "80%",
                }}
              >
                <Form.Item required>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "32px",
                      alignItems: "end",
                      maxWidth: "100%",
                    }}
                  >
                    <div className="kmlUploadFormTitle">ID do cliente:</div>
                    <Input
                      type="text"
                      name="producerName"
                      placeholder="AA123456"
                      value={generalInfo.producerName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setGeneralInfo({
                          ...generalInfo,
                          producerName: value,
                        });

                        setFormDataList((prevFormDataList) =>
                          prevFormDataList.map((formData) => ({
                            ...formData,
                            proposalId: value,
                          }))
                        );
                      }}
                      style={{ width: "290px", height: "44px" }}
                      required
                    />
                  </div>
                </Form.Item>
                <Form.Item required>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "32px",
                      alignItems: "center",
                    }}
                  >
                    <div className="kmlUploadFormTitle">Nome da fazenda:</div>
                    <Input
                      type="text"
                      name="farmName"
                      placeholder="Fazenda Pessegueiro"
                      value={generalInfo.farmName}
                      onChange={(e) =>
                        setGeneralInfo({
                          ...generalInfo,
                          farmName: e.target.value,
                        })
                      }
                      style={{ width: "290px", height: "44px" }}
                      required
                    />
                  </div>
                </Form.Item>
              </div>
              <Form.Item required>
                <Upload.Dragger
                  className="customDragger"
                  fileList={formFileList}
                  onChange={onFileChange}
                  beforeUpload={beforeUpload}
                  accept=".kml, application/vnd.google-earth.kml+xml, .geojson"
                  multiple={true}
                  showUploadList={true}
                  style={{ top: "70px" }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "Hug (208px)px",
                      width: "Fixed (588px)px",
                      padding: "16px 0px 16px 0px",
                      gap: "20px",
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined style={{ color: "#9236D9" }} />
                    </p>
                    <p className="ant-upload-text">
                      Clique ou arraste os arquivos KML ou GeoJSON para esta
                      área
                    </p>
                    <p className="ant-upload-hint">
                      Suporte para envios únicos ou em grupo. Para arquivos de
                      geometria simples, para multigeometria apenas um aceito
                      por vez
                    </p>
                  </div>

                  <input
                    id="hiddenFileInput"
                    type="file"
                    accept=".kml, application/vnd.google-earth.kml+xml, .geojson"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      const files = Array.from(e.target.files || []);
                      onFileChange({ fileList: files });
                    }}
                  />
                </Upload.Dragger>
              </Form.Item>

              <Form.Item required style={{ top: "70px", position: "relative" }}>
                <div
                  className="formButtons"
                  style={{
                    justifyContent: "right",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={nextStep}
                    className="buttonCustom"
                    loading={loading}
                    disabled={
                      !generalInfo.producerName ||
                      !generalInfo.farmName ||
                      formFileList.length === 0
                    }
                  >
                    Continuar
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
          {currentStep === 2 && (
            <div style={{ width: "100%" }}>
              <div className="kmlUploadContent">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <Checkbox
                    onChange={() => handleCheckboxChange("option1")}
                    checked={selectedValues.includes("option1")}
                    disabled={!formDataList[0]?.cropType} // Desabilita até o campo cropType ser preenchido
                    style={{ color: "#B64CFF" }}
                  >
                    <span
                      style={{
                        color: "black",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Usar mesma Cultura para todos.
                    </span>
                  </Checkbox>
                  <Checkbox
                    onChange={() => handleCheckboxChange("option2")}
                    checked={selectedValues.includes("option2")}
                    disabled={!formDataList[0]?.serviceType} 
                    style={{ color: "#B64CFF" }}
                  >
                    <span
                      style={{
                        color: "black",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Usar mesmo Serviço para todos.
                    </span>
                  </Checkbox>
                  <Checkbox
                    onChange={() => handleCheckboxChange("option3")}
                    checked={selectedValues.includes("option3")}
                    disabled={!formDataList[0]?.cycleType} 
                    style={{ color: "#B64CFF" }}
                  >
                    <span
                      style={{
                        color: "black",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Usar mesmo Ciclo para todos.
                    </span>
                  </Checkbox>
                  <Checkbox
                    onChange={() => handleCheckboxChange("option4")}
                    checked={selectedValues.includes("option4")}
                    disabled={!formDataList[0]?.soilType} 
                    style={{ color: "#B64CFF" }}
                  >
                    <span
                      style={{
                        color: "black",
                        lineHeight: "24px",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Usar mesmo Solo para todos.
                    </span>
                  </Checkbox>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    marginTop: "-96px",
                    paddingBottom: "42px",
                  }}
                >
                  <Form.Item required>
                    <Upload
                      accept=".csv"
                      beforeUpload={handleCSVUpload}
                      showUploadList={false}
                    >
                      <Button
                        className="buttonCustomWhite"
                        icon={<UploadOutlined />}
                        style={{ alignSelf: "flex-end" }}
                        key="newBulkUpload"
                        disabled={
                          !generalInfo.producerName || !generalInfo.farmName
                        }
                      >
                        Envio de arquivo .csv
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
              <div className="fileListContainer">
                {formFileList.map((file, index) =>
                  renderItem(null, file, formFileList, null)
                )}
              </div>
              <Form.Item required>
                <div className="formButtons">
                  <Button
                    onClick={prevStep}
                    className="buttonCustomWhite"
                    style={{ marginRight: "8px" }}
                  >
                    Voltar
                  </Button>
                  <Button
                    type="primary"
                    onClick={nextStepSubmit}
                    className="buttonCustom"
                    loading={loading}
                    icon={<SendOutlined />}
                    disabled={isUploadButtonDisabled()}
                  >
                    Enviar para Análise
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
          {currentStep === 3 && !timeoutError && (
            <Result
              status="success"
              title="Upload realizado com sucesso"
              extra={[
                <Button
                  className="buttonCustomWhite"
                  onClick={resetForm}
                  key="newForm"
                >
                  Nova consulta
                </Button>,
                <Button
                  className="buttonCustom"
                  onClick={() => {
                    const senseCount = formDataList.filter(
                      (formData) => formData.serviceType === "sense"
                    ).length;
                    const auraCount = formDataList.filter(
                      (formData) => formData.serviceType === "aura"
                    ).length;
                    const visionCount = formDataList.filter(
                      (formData) => formData.serviceType === "vision"
                    ).length;

                    const maxCount = Math.max(
                      senseCount,
                      auraCount,
                      visionCount
                    );

                    let destination = "/aura";
                    if (senseCount === maxCount) destination = "/sense";
                    if (visionCount === maxCount) destination = "/vision";

                    navigate(destination);
                  }}
                  key="seeHistory"
                >
                  Ver no histórico
                </Button>,
              ]}
            />
          )}
          {timeoutError && (
            <Result
              status="error"
              title="Erro de Tempo de Solicitação"
              subTitle="O tempo de solicitação esgotou. Por favor, tente novamente."
              extra={[
                <Button
                  className="buttonCustomWhite"
                  onClick={resetForm}
                  key="newForm"
                >
                  Nova consulta
                </Button>,
              ]}
            />
          )}
        </Form>
      </div>
    </div>
  );
}

export default KmlUpload;
