import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./SensePlusMap.css";
import SensePlusPopover from "./SensePlusPopover";
import { MonitorOutlined } from "@ant-design/icons";

function SensePlusMap({ tickets, showEsg, mapboxToken }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const popupRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mapboxToken) return;

    mapboxgl.accessToken = mapboxToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/felipegalo/cluhf94ix00zu01qlhwrx8oyq",
      center: [-52, -27],
      zoom: 6,
    });

    mapRef.current.on("load", () => {
      // Adicionar fonte e camadas de tickets no mapa (vazia inicialmente)
      mapRef.current.addSource("tickets", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      mapRef.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "tickets",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#2288B4",
            10,
            "#FF7B00",
            30,
            "#F5222D",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            12,
            16,
            24,
            28,
            32,
          ],
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      mapRef.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "tickets",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#ffffff", // Cor branca para o texto
        },
      });

      mapRef.current.addLayer({
        id: "unclustered-points",
        type: "circle",
        source: "tickets",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": [
            "case",
            ["get", "hasSicarOverlap"],
            "#F5222D",
            ["get", "hasNotifications"],
            "#FF7B00",
            "#2288B4",
          ],
          "circle-radius": 6,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      // Setup interactivity
      mapRef.current.on("click", "clusters", (e) => {
        const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;

        mapRef.current.getSource("tickets").getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            mapRef.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
        );
      });

      mapRef.current.on("click", "unclustered-points", (e) => {
        const feature = e.features[0];
      
        let notifications = [];
        let overlaps = [];
        try {
          notifications = JSON.parse(feature.properties.notifications);
          overlaps = JSON.parse(feature.properties.sicar_overlap);

        } catch (error) {
          console.error("Falha ao parsear notifications:", error);
        }
      
        setPopupInfo({
          id: feature.properties.id,
          clientId: feature.properties.clientId || "N/A",
          proposalId: feature.properties.proposalId || "N/A",
          area: feature.properties.area || 0,
          sicar_overlap: overlaps,
          notifications: notifications,
          centerCoords: {
            long: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
          },
        });
      });

      mapRef.current.on("mouseenter", "clusters", () => {
        mapRef.current.getCanvas().style.cursor = "pointer";
      });

      mapRef.current.on("mouseleave", "clusters", () => {
        mapRef.current.getCanvas().style.cursor = "";
      });

      setIsMapLoaded(true);
    });

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [mapboxToken]);

  useEffect(() => {
    if (mapRef.current && tickets) {
      const geoJsonData = {
        type: "FeatureCollection",
        features: tickets.map((ticket) => ({
          type: "Feature",
          properties: {
            id: ticket.id,
            clientId: ticket.clientId,
            proposalId: ticket.proposalId,
            area: ticket.area,
            hasSicarOverlap: ticket.sicar_overlap.length > 0,
            hasNotifications: ticket.notifications.length > 0,
            notifications: ticket.notifications,
            sicar_overlap: ticket.sicar_overlap,
          },
          geometry: {
            type: "Point",
            coordinates: [ticket.centerCoords.long, ticket.centerCoords.lat],
          },
        })),
      };

      const source = mapRef.current.getSource("tickets");
      if (source) {
        source.setData(geoJsonData);
      }
    }
  }, [tickets, isMapLoaded]);

  useEffect(() => {
    if (mapRef.current) {
      const onStyleLoad = () => {
        if (showEsg) {
          mapRef.current.setPaintProperty("unclustered-points", "circle-color", [
            "case",
            ["get", "hasSicarOverlap"],
            "#F5222D",
            ["get", "hasNotifications"],
            "#FF7B00",
            "#2288B4",
          ]);
        } else {
          mapRef.current.setPaintProperty("unclustered-points", "circle-color", [
            "case",
            ["get", "hasNotifications"],
            "#FF7B00",
            "#2288B4",
          ]);
        }
      };
  
      if (mapRef.current.isStyleLoaded()) {
        onStyleLoad();
      } else {
        mapRef.current.once("style.load", onStyleLoad);
      }
    }
  }, [showEsg]);

  useEffect(() => {
    if (popupInfo && mapRef.current) {
      if (popupRef.current) {
        popupRef.current.remove();
      }

      const popupNode = document.createElement("div");
      ReactDOM.render(
        <div className="sensePlusPopupContainer">
          <SensePlusPopover ticket={popupInfo} />
          <Button
            className="sensePlusPopupButton"
            icon={<MonitorOutlined />}
            onClick={() => navigate(`/sense/${popupInfo.id}`)}
          >
            Ver no monitoramento
          </Button>
        </div>,
        popupNode
      );

      popupRef.current = new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: true,
        anchor: "bottom",
        className: "sensePlusPopup",
      })
        .setLngLat([popupInfo.centerCoords.long, popupInfo.centerCoords.lat])
        .setDOMContent(popupNode)
        .addTo(mapRef.current);

      popupRef.current.on("close", () => setPopupInfo(null));
    }
  }, [popupInfo]);

  return (
    <div className="mapVis" style={{ height: "100%", width: "100%" }}>
      <div ref={mapContainerRef} style={{ height: "100%", width: "100%" }} />
    </div>
  );
}

export default SensePlusMap;