import { useState, useEffect, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { Segmented, Spin } from "antd";
import { fetchMapContext } from "../api/api";
import { LoadingOutlined } from "@ant-design/icons";

import "./AuraMap.css";
import { Recycling, ScienceOutlined, YardOutlined } from "@mui/icons-material";
import AuraMapGraph from "./AuraMapGraph";

const AuraMap = ({ id, coords, graphData, ndviGraphData, classificationRecords, isVisible }) => {
  const [selectedOption, setSelectedOption] = useState("ndvi");
  const [loading, setLoading] = useState(false);
  const [retry, setRetry] = useState(0);

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (retry === 0) handleMapData("ndvi");

    if (retry >= 1 && retry <= 3) {
      const timeout = setTimeout(async () => {
        try {
          await handleMapData(selectedOption);
        } catch (error) {
          console.error("Error in handleMapData:", error);
        }
      }, 20000);

      return () => clearTimeout(timeout);
    }
  }, [retry]);

  useEffect(() => {
    if (isVisible && mapRef.current) {
      setTimeout(() => mapRef.current.resize(), 50);
    }
  }, [isVisible]);

  const handleMapData = async (optionValue) => {
    if (optionValue !== selectedOption) setRetry(1);
  
    setLoading(true);
    setSelectedOption(optionValue);
  
    try {
      clearMap();
      const data = await fetchMapContext(optionValue, id);
      const mapReport = data.map_report_data;
  
      mapboxgl.accessToken = data.mapbox_token;
  
      if (!mapRef.current) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/satellite-v9',
          zoom: 14,
          center: [coords[1], coords[0]],
          attributionControl: false,
          dragRotate: false,
          pitchWithRotate: false,
        });
  
        mapRef.current.on('load', () => {
          updateMapLayers(mapReport);
        });
      } else if (mapRef.current.isStyleLoaded()) {
        updateMapLayers(mapReport);
      }
    } catch (error) {
      setRetry((retry) => retry + 1);
      console.error("Failed to fetch map context:", error);
    } finally {
      setLoading(false);
    }
  };

  const clearMap = () => {
    const sourceId = "map-source";
    const layerId = "map-layer";
  
    if (mapRef.current) {
      if (mapRef.current.getLayer(layerId)) {
        mapRef.current.removeLayer(layerId);
      }
      if (mapRef.current.getSource(sourceId)) {
        mapRef.current.removeSource(sourceId);
      }
    }
  };
  
  const updateMapLayers = (mapReport) => {
    const sourceId = "map-source";
    const layerId = "map-layer";
  
    const colorMap = mapReport.color_map
      .split(",")
      .map((item) => item.trim().replace(/\"/g, ""))
      .reduce((acc, curr, idx, src) => {
        if (idx % 2 === 0) {
          acc.push([parseFloat(curr - 0.2), src[idx + 1]]);
        }
        return acc;
      }, []);
  
    mapRef.current.addSource(sourceId, {
      type: 'geojson',
      data: mapReport.geojson_data,
    });
  
    mapRef.current.addLayer({
      id: layerId,
      source: sourceId,
      type: "fill",
      paint: {
        "fill-color": [
          "step",
          ["get", "elevation"],
          "#000000",
          ...colorMap.flat(),
        ],
        "fill-opacity": 0.8,
      },
    });
  };

  const legendPathOption = {
    ndvi: "/assets/mapLegends/Plant-Health.svg",
    om: "/assets/mapLegends/OrgMatter.svg",
    ndre: "/assets/mapLegends/Nitrogen.svg",
  };

  const buttonOptions = [
    {
      value: "ndvi",
      label: "Saúde das plantas",
      icon: <Recycling style={{ fontSize: 16, color: "#434449" }} />,
    },
    {
      value: "om",
      label: "Matéria orgânica",
      icon: <YardOutlined style={{ fontSize: 16, color: "#434449" }} />,
    },
    {
      value: "ndre",
      label: "Nitrogênio",
      icon: <ScienceOutlined style={{ fontSize: 16, color: "#434449" }} />,
    },
  ];

  return (
    <div className="auraMapContainer">
      <Segmented
        options={buttonOptions.map((option) => ({
          label: (
            <div className="auraMapButtonLabel">
              {option.icon}
              {option.label}
            </div>
          ),
          value: option.value,
        }))}
        onChange={handleMapData}
        block
        value={selectedOption}
      />
      <div className="auraMapVis">
        {loading && (
          <div className="auraMapLoading">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    color: "#B64CFF",
                    fontSize: "40px",
                  }}
                  spin
                />
              }
            />
          </div>
        )}
        <div id="map" ref={mapContainerRef} style={{ height: '100%' }}>
          {!loading && <div
            style={{
              position: "absolute",
              bottom: 12,
              right: 12,
              zIndex: 1,
            }}
          >
            <img
              src={legendPathOption[selectedOption]}
              alt="Legenda"
              style={{ width: "40px", height: "auto" }}
            />
          </div>}
        </div>
      </div>
      <div className="auraMapGraphContainer">
        <AuraMapGraph
          selectedChart={selectedOption}
          data={graphData}
          ndviData={ndviGraphData}
          classificationRecords={classificationRecords}
        />
      </div>
    </div>
  );
};

export default AuraMap;
