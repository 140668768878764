import React, { useState, useEffect, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import VisionErrorPopover from "./VisionErrorPopover";
import { polygonStylePatterns } from "./polyStyles";
import "./Vision.css";
import { appToEsgId } from "./AppToEsgId";

const esgNameDictionary = {
  1: "Área de Preservação Permanente",
  5: "Área do Imóvel - SNCI",
  6: "Área de Pousio",
  7: "Área de Topo de Morro",
  8: "Banhado",
  9: "Borda de Chapada",
  10: "Hidrografia",
  11: "Manguezal",
  12: "Nascente/Olho d'Água",
  13: "Reserva Legal",
  14: "Restinga",
  15: "Servidão Administrativa",
  16: "Uso Restrito",
  17: "Vegetação Nativa",
  18: "Vereda",
  19: "Desmatamento Prodes",
  20: "ICMBio",
  21: "IBAMA",
  22: "Assentamento",
};

const VisionEsgMap = ({ data, isVisible }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  
  const [activeLayerIds, setActiveLayerIds] = useState([]);
  const [hasIbama, setHasIbama] = useState(false);
  const [esgIdList, setEsgIdList] = useState([]);
  
  if (!data) return null;

  useEffect(() => {
    mapboxgl.accessToken = data.mapbox_token;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      zoom: 10,
      center: [-52, -30],
      attributionControl: false,
      dragRotate: false,
      pitchWithRotate: false,
    });

    mapRef.current.on('load', () => {
      data.area_imovel.features.forEach((feature, index) => {
        mapRef.current.addSource(`car-source-${index}`, {
          type: 'geojson',
          data: feature,
        });
        mapRef.current.addLayer({
          id: `car-layer-${index}-fill`,
          source: `car-source-${index}`,
          ...polygonStylePatterns.mapboxVisionCarArea.fill,
          layout: {
            visibility: "visible",
          },
        });
        mapRef.current.addLayer({
          id: `car-layer-${index}-border`,
          source: `car-source-${index}`,
          ...polygonStylePatterns.mapboxVisionCarArea.border,
          layout: {
            visibility: "visible",
          },
        });
      });
      setActiveLayerIds((prev) => [...prev, "car-layer"]);

      const uniqueEsgIds = new Set(
        data?.esg_detections?.features.map((feature) =>
          appToEsgId[feature.properties.app.toUpperCase()]
        )
      );
    
      uniqueEsgIds.delete(21);
      setEsgIdList([...uniqueEsgIds]);

      [...data.esg_detections.features]
        .filter((feature) => {
          const idEsg = appToEsgId[feature.properties.app.toUpperCase()];
          return idEsg !== 21;
        })
        .reverse()
        .map((feature, index) => {
          const app = feature.properties.app;
          const idEsg = appToEsgId[app.toUpperCase()];

          mapRef.current.addSource(`esg-${idEsg}-source-${index}`, {
            type: 'geojson',
            data: feature,
          });
          mapRef.current.addLayer({
            id: `esg-${idEsg}-layer-${index}`,
            source: `esg-${idEsg}-source-${index}`,
            ...polygonStylePatterns[idEsg],
            layout: {
              visibility: "visible",
            },
          });
          setActiveLayerIds((prev) => [...prev, `esg-${idEsg}-layer`]);
      });

      mapRef.current.addSource('kml', {
        type: 'geojson',
        data: data.ticket_geometry
      });
      mapRef.current.addLayer({
        id: 'kml-layer',
        source: 'kml',
        ...polygonStylePatterns.mapboxVisionKmlArea,
        layout: {
          visibility: "visible",
        },
      });
      setActiveLayerIds((prev) => [...prev, "kml-layer"]);

      const ibamaFeatures =
        data?.upsell_data?.ibama_tracking?.overlap_geometries?.features || [];

      if (ibamaFeatures.length > 0) {
        setHasIbama(true);
        ibamaFeatures.forEach((feature, index) => {
          mapRef.current.addSource(`ibama-source-${index}`, {
            type: 'geojson',
            data: feature,
          });
          mapRef.current.addLayer({
            id: `ibama-layer-${index}`,
            source: `ibama-source-${index}`,
            ...polygonStylePatterns[21],
            layout: {
              visibility: "visible",
            },
          });
        });
        setActiveLayerIds((prev) => [...prev, "ibama-layer"]);
      }
    });

    const bounds = new mapboxgl.LngLatBounds();

    const coordinates = data.ticket_geometry.geometry.coordinates[0];
    coordinates.forEach(([lng, lat]) => bounds.extend([lng, lat]));

    mapRef.current.fitBounds(bounds, {
      padding: 20,
      maxZoom: 14,
      duration: 0,
    });
  }, [data]);

  const toggleLayerVisibility = (layerPrefix) => {
    const currentVisibility = activeLayerIds.includes(layerPrefix) ? "none" : "visible";

    const layers = mapRef.current.getStyle().layers;
    layers.forEach((layer) => {
      if (layer.id.startsWith(layerPrefix)) {
        mapRef.current.setLayoutProperty(layer.id, "visibility", currentVisibility);
      }
    });
  
    setActiveLayerIds((prev) =>
      prev.includes(layerPrefix)
        ? prev.filter((id) => id !== layerPrefix)
        : [...prev, layerPrefix]
    );
  };

  useEffect(() => {
    if (isVisible && mapRef.current) {
      setTimeout(() => mapRef.current.resize(), 50);
    }
  }, [isVisible]);  

  return (
    <div
      className="visionReportElementCard"
      style={{
        height: "768px",
        position: "relative",
        borderRadius: "8px",
      }}
    >
      <div className="visionMapLegendCard">
        <div className="visionMapLegendTitle">Legenda</div>
        <div className="visionMapLegendContent">
          {hasIbama && (
            <div
              className="visionMapLegendLine"
              onClick={() => toggleLayerVisibility("ibama-layer")}
            >
              <div
                className="visionMapLegendSquare"
                style={{
                  backgroundColor:
                    polygonStylePatterns[21].color,
                  opacity: activeLayerIds.includes("ibama-layer") ? 1 : 0.5,
                  cursor: "pointer",
                }}
              ></div>
              <div className="visionLegendText">Embargo IBAMA</div>
            </div>
          )}
          <div
            className="visionMapLegendLine"
            onClick={() => toggleLayerVisibility("kml-layer")}
          >
            <div
              className="visionMapLegendSquare"
              style={{
                backgroundColor:
                  polygonStylePatterns.mapboxVisionKmlArea.color,
                opacity: activeLayerIds.includes("kml-layer") ? 1 : 0.5,
                cursor: "pointer",
              }}
            />
            <div className="visionLegendText">Área do talhão</div>
          </div>
          {esgIdList.map((id, index) => (
            <div
              key={index}
              className="visionMapLegendLine"
              onClick={() => toggleLayerVisibility(`esg-${id}-layer`)}
            >
              <div
                className="visionMapLegendSquare"
                style={{
                  backgroundColor: `${polygonStylePatterns[id]?.color || "#999999"}`,
                  opacity: activeLayerIds.includes(`esg-${id}-layer`) ? 1 : 0.5,
                  cursor: "pointer",
                }}
              ></div>
              <div className="visionLegendText">
                {esgNameDictionary[id] ?? `ID ${id}`}
              </div>
            </div>
          ))}
          <div
            className="visionMapLegendLine"
            onClick={() => toggleLayerVisibility("car-layer")}
          >
            <div
              className="visionMapLegendSquare"
              style={{
                backgroundColor:
                  polygonStylePatterns.mapboxVisionCarArea.color,
                opacity: activeLayerIds.includes("car-layer") ? 1 : 0.5,
                cursor: "pointer",
              }}
            ></div>
            <div className="visionLegendText">Área do imóvel rural</div>
          </div>
        </div>
      </div>
      <div style={{ height: "100%", width: "100%", padding: "4px" }}>
        <div id="map" ref={mapContainerRef} style={{ height: '100%' }}/>
        {data?.status === "area_imovel_not_found" && <VisionErrorPopover />}
      </div>
    </div>
  );
};

export default VisionEsgMap;
