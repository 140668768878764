import React, { useState, useEffect, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import VisionErrorPopover from "./VisionErrorPopover";
import { polygonStylePatterns } from "./polyStyles";
import "./Vision.css";
import "./VisionUpsellDashboard.css";

const VisionCropscanMap = ({ data, isVisible }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  
  const [activeLayerIds, setActiveLayerIds] = useState([]);
  const [cropList, setCropList] = useState([]);
  const [hasEsg, setHasEsg] = useState(false);
  
  if (!data) return null;

  function getCropName(id) {
    const mapLabelToCrop = {
      204: "soja",
      3: "milho",
      7: "arroz",
      4: "outro",
    };

    return mapLabelToCrop[id] || "outro";
  }

  const groupFeaturesByLabel = (features) => {
    const grouped = features.reduce((acc, feature) => {
      if (!feature.geometry) {
        return acc;
      }

      const label = feature.properties.label;
      const crop = getCropName(label);

      if (!acc[crop]) {
        acc[crop] = [];
      }
      acc[crop].push(feature);
      return acc;
    }, {});

    if (grouped.outro) {
      const outroFeatures = grouped.outro;
      delete grouped.outro;
      // grouped.outro = outroFeatures;
    }

    return grouped;
  };

  useEffect(() => {
    mapboxgl.accessToken = data.mapbox_token;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      zoom: 10,
      center: [-52, -30],
      attributionControl: false,
      dragRotate: false,
      pitchWithRotate: false,
    });

    mapRef.current.on('load', () => {
      data.area_imovel.features.forEach((feature, index) => {
        mapRef.current.addSource(`car-source-${index}`, {
          type: 'geojson',
          data: feature,
        });
        mapRef.current.addLayer({
          id: `car-layer-${index}-fill`,
          source: `car-source-${index}`,
          ...polygonStylePatterns.mapboxVisionCarArea.fill,
          layout: {
            visibility: "visible",
          },
        });
        mapRef.current.addLayer({
          id: `car-layer-${index}-border`,
          source: `car-source-${index}`,
          ...polygonStylePatterns.mapboxVisionCarArea.border,
          layout: {
            visibility: "visible",
          },
        });
      });
      setActiveLayerIds((prev) => [...prev, "car-layer"]);

      const cropScanDetectionsByLabel = data?.crop_scan_detections
        ? groupFeaturesByLabel(data.crop_scan_detections.features)
        : {};
      setCropList(Object.keys(cropScanDetectionsByLabel));

      Object.keys(cropScanDetectionsByLabel).reverse().map((crop, index) => {
        mapRef.current.addSource(`crop-${crop}-source-${index}`, {
          type: 'geojson',
          data: {
            type: "FeatureCollection",
            features: cropScanDetectionsByLabel[crop],
          },
        });
        mapRef.current.addLayer({
          id: `crop-${crop}-layer-${index}`,
          source: `crop-${crop}-source-${index}`,
          ...polygonStylePatterns[[`${crop}Area`]],
          layout: {
            visibility: "visible",
          },
        });
        setActiveLayerIds((prev) => [...prev, `crop-${crop}-layer`]);
      });

      mapRef.current.addSource('esg-source', {
        type: 'geojson',
        data: data.esg_detections
      });
      mapRef.current.addLayer({
        id: 'esg-layer',
        source: 'esg-source',
        ...polygonStylePatterns.mapboxVisionEsgArea,
        layout: {
          visibility: "visible",
        },
      });
      setActiveLayerIds((prev) => [...prev, "esg-layer"]);
      setHasEsg(data.esg_detections?.features.length > 0)

      mapRef.current.addSource('kml', {
        type: 'geojson',
        data: data.ticket_geometry
      });
      mapRef.current.addLayer({
        id: 'kml-layer',
        source: 'kml',
        ...polygonStylePatterns.mapboxVisionKmlArea,
        layout: {
          visibility: "visible",
        },
      });
      setActiveLayerIds((prev) => [...prev, "kml-layer"]);
    });

    const bounds = new mapboxgl.LngLatBounds();

    const coordinates = data.ticket_geometry.geometry.coordinates[0];
    coordinates.forEach(([lng, lat]) => bounds.extend([lng, lat]));

    mapRef.current.fitBounds(bounds, {
      padding: 20,
      maxZoom: 14,
      duration: 0,
    });
  }, [data]);

  const toggleLayerVisibility = (layerPrefix) => {
    const currentVisibility = activeLayerIds.includes(layerPrefix) ? "none" : "visible";

    const layers = mapRef.current.getStyle().layers;
    layers.forEach((layer) => {
      if (layer.id.startsWith(layerPrefix)) {
        mapRef.current.setLayoutProperty(layer.id, "visibility", currentVisibility);
      }
    });
  
    setActiveLayerIds((prev) =>
      prev.includes(layerPrefix)
        ? prev.filter((id) => id !== layerPrefix)
        : [...prev, layerPrefix]
    );
  };

  useEffect(() => {
    if (isVisible && mapRef.current) {
      setTimeout(() => mapRef.current.resize(), 50);
    }
  }, [isVisible]);

  return (
    <div
      className="visionReportElementCard"
      style={{
        width: "100%",
        height: "1040px",
        position: "relative",
      }}
    >
      <div className="visionMapLegendCard">
        <div className="visionMapLegendTitle">Legenda</div>
        <div className="visionMapLegendContent">
          <div
            className="visionMapLegendLine"
            onClick={() => toggleLayerVisibility("kml-layer")}
          >
            <div
              className="visionMapLegendSquare"
              style={{
                backgroundColor:
                  polygonStylePatterns.mapboxVisionKmlArea.color,
                opacity: activeLayerIds.includes("kml-layer") ? 1 : 0.5,
                cursor: "pointer",
              }}
            />
            <div className="visionLegendText">Área do talhão</div>
          </div>
          {hasEsg && <div
            className="visionMapLegendLine"
            onClick={() => toggleLayerVisibility("esg-layer")}
          >
            <div
              className="visionMapLegendSquare"
              style={{
                backgroundColor: polygonStylePatterns.mapboxVisionEsgArea.color,
                opacity: activeLayerIds.includes("esg-layer") ? 1 : 0.5,
                cursor: "pointer",
              }}
            />
            <div className="visionLegendText">Área de ESG</div>
          </div>}
          {cropList.map((crop) => (
            <div
              key={crop}
              className="visionMapLegendLine"
              onClick={() => toggleLayerVisibility(`crop-${crop}-layer`)}
            >
              <div
                className={`visionMapLegendSquare`}
                style={{
                  backgroundColor: polygonStylePatterns[`${crop}Area`].color,
                  opacity: activeLayerIds.includes(`crop-${crop}-layer`) ? 1 : 0.5,
                  cursor: "pointer",
                }}
              />
              <div className="visionLegendText">Área de {crop} - 23/24</div>
            </div>
          ))}
          <div
            className="visionMapLegendLine"
            onClick={() => toggleLayerVisibility("car-layer")}
          >
            <div
              className="visionMapLegendSquare"
              style={{
                backgroundColor:
                  polygonStylePatterns.mapboxVisionCarArea.color,
                opacity: activeLayerIds.includes("car-layer") ? 1 : 0.5,
                cursor: "pointer",
              }}
            ></div>
            <div className="visionLegendText">Área do imóvel rural</div>
          </div>
        </div>
      </div>
      <div style={{ height: "100%", width: "100%", padding: "4px" }}>
        <div id="map" ref={mapContainerRef} style={{ height: '100%' }}/>
        {data?.status === "area_imovel_not_found" && (
          <div className="popoverContainer">
            <VisionErrorPopover />
          </div>
        )}
      </div>
    </div>
  );
};

export default VisionCropscanMap;
