import React from "react";
import {Card, Typography, Flex, Spin} from "antd";
import classifyByHarvestYear from "../../components/dashboard/functions/ClassifyByHarvestYear";
import getScoreInfo from "../../components/dashboard/functions/GetScoreInfo";
import {InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";

import ReactApexChart from "react-apexcharts";

import "./AuraHistoricalParameters.css";

const {Text} = Typography;

const getCurrentYear = () => new Date().getFullYear();

const validCultures = ["soja", "milho", "maize"]
const formatDate = (date) => {

  const day = String(Math.min(date.getDate() + 1, 31)).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;

};

const generateYearsData = (data) => {
    let currentYear = getCurrentYear() - 1;
    let years = [];
    let yearsData = {};

    if (!data || !Array.isArray(data)) {
        return yearsData;
    }

    for (let i = 0; i < 5; i++) {
        years.push(currentYear - i);
    }

    years.forEach((year) => {
        yearsData[year] = null;
    });

    data.forEach((item) => {
        let sowingDateYear = new Date(item.sowing_date).getFullYear();
        let harvestDateYear = new Date(item.harvest_date).getFullYear();

        if (sowingDateYear == harvestDateYear) {
            sowingDateYear = sowingDateYear - 1;
        }
        if (yearsData.hasOwnProperty(sowingDateYear)) {
            yearsData[sowingDateYear] = item;
        }
    });

    return yearsData;
};

const getCultureName = (value) => {
    if (value == "soja") return "Soja";
    if (value == "maize" || value == "milho") return "Milho";
    if (value == "rice" || value == "arroz") return "Arroz";
    if (value == "others") return "Outra Cultura";
    return value;
};

const CultureEstimateSectionHeader = ({culture}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8
        }}
    >
        {validCultures.includes(culture) && (
            <img
                src="/icons/grass-icon.svg"
                alt="Plant Stress Icon"
                style={{width: "40px", height: "40px"}}
            />
        )}

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "center",
            }}
        >
            <Text className="cultureEstimateAura">Estimativa de culturas:</Text>
            <Text className="cultureEstimateValue">
                {culture ? getCultureName(culture) : "Analisando"}
            </Text>
        </div>
    </div>
);

const CultureEstimateSection = ({culture}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8
        }}
    >
        {validCultures.includes(culture) && (
            <img
                src="/icons/grass-icon.svg"
                alt="Plant Stress Icon"
                style={{width: "40px", height: "40px"}}
            />
        )}

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "center",
                textAlign: validCultures.includes(culture) ? "left" : "center",
            }}
        >
            <Text className="cultureEstimateAura">Estimativa de culturas:</Text>
            <Text className="cultureEstimateValue">
                {culture ? getCultureName(culture) : "Analisando"}
            </Text>
        </div>
    </div>
);

const PlantStressIcon = () => (
    <Flex vertical align="center">
        <img
            src="/icons/grass-icon.svg"
            alt="Plant Stress Icon"
            className="iconLarge"
        />
    </Flex>
);
const CloudCoverageSection = () => (
    <div className="cloudCoverageSection">
        <Flex vertical align="center">
            <img src="/icons/cloud.svg" alt="Cloud Icon" className="iconLarge"/>
            <div className="highCloudCoverageTitleAura">Alta cobertura por nuvens</div>
            <div className="highCloudCoverageDescriptionContainer">
                <div className="highCloudCoverageDescriptionAura">
                    Alta concentração de nuvens, impossibilitando a análise por imagens de
                    satélite.
                </div>
            </div>
        </Flex>
    </div>
);

const getProductivityRange = (value, item) => {
    if (getCultureName(item) === "Milho") {
        if (value > 220) return "+220 Sa/Ha";
        if (value >= 180) return "180-220 Sa/Ha";
        if (value >= 140) return "140-180 Sa/Ha";
        if (value >= 100) return "100-140 Sa/Ha";
        if (value >= 60) return "60-100 Sa/Ha";
        return "Menos de 60 Sa/Ha";
    } else {
        if (value > 55) return "+55 Sa/Ha";
        if (value >= 45) return "45-55 Sa/Ha";
        if (value >= 35) return "35-45 Sa/Ha";
        if (value >= 25) return "25-35 Sa/Ha";
        if (value >= 15) return "15-25 Sa/Ha";
        return "Menos de 15 Sa/Ha";
    }
};

const AuraHistoricalParameters = ({data}) => {
    const yearsData = generateYearsData(data);

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 16
            }}
        >
            {Object.keys(yearsData)
                .sort((a, b) => b - a)
                .slice(0, 1)
                .map((year) => {
                    const item = yearsData[year];
                    const riskScore = item ? item.risk_score : 0;

                    return (
                        <div key={year} className="customCardFirstItem">
                            {item ? (
                                item.model_messages === "low_image_available" ||
                                item.model_messages === "unhandled_exception" ||
                                (item.risk_score === 0 &&
                                    item.calculation_status === "error") ? (
                                    <CloudCoverageSection/>
                                ) : (
                                    <div className="customCardFirstContent">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 8,
                                            }}
                                        >
                                            <div className="auraSafra">
                                                {`Safra ${year.toString().slice(-2)} / ${(Number(year) + 1)
                                                    .toString()
                                                    .slice(-2)}`}
                                            </div>
                                            {validCultures.includes(item.culture) ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: 8,
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div
                                                        className="auraFirstRiskScore"
                                                        style={{
                                                            backgroundColor: getScoreInfo(riskScore).color,
                                                        }}
                                                    >
                                                        {riskScore > 0 ? riskScore : "N/D"}
                                                    </div>
                                                    <div className="HeaderProductivityValue">
                                                        {getProductivityRange(item.biomass_productivity, item.culture)}
                                                    </div>
                                                </div>
                                            ) : (
                                                <PlantStressIcon/>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: 8,
                                            }}
                                        >
                                            <CultureEstimateSectionHeader
                                                culture={item.culture}
                                            />

                                            {validCultures.includes(item.culture) && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: 8
                                                    }}
                                                >
                                                    <img
                                                        src="/icons/date_range.svg"
                                                        alt="Farming Icon"
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",

                                                        }}
                                                    >
                                                        <Text className="plantingHarvestTitle">
                                                            Plantio e colheita:
                                                        </Text>
                                                        <Text className="plantingHarvestValue">
                                                            {`${formatDate(new Date(item.sowing_date))} - ${formatDate(new Date(item.harvest_date))}`}
                                                        </Text>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div>Carregando dados...</div>
                            )}
                        </div>
                    );
                })}

            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "8px",
                    marginTop: "2px"
                }}
            >
                {Object.keys(yearsData)
                    .sort((a, b) => b - a)
                    .slice(1)
                    .map((year) => {
                        const item = yearsData[year];
                        const riskScore = item ? item.risk_score : 0;

                        return (
                            <div key={year} className="customCardOtherItem">
                                <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                                    <div className="auraSafra">
                                        {`Safra ${year.toString().slice(-2)} / ${(Number(year) + 1)
                                            .toString()
                                            .slice(-2)}`}
                                    </div>
                                    {item ? (
                                        item.model_messages === "low_image_available" ||
                                        item.model_messages === "unhandled_exception" ||
                                        (item.risk_score === 0 &&
                                            item.calculation_status === "error") ? (
                                            <CloudCoverageSection/>
                                        ) : (
                                            <>
                                                <div
                                                    className="topInfoContainer"
                                                    style={{display: "flex", top: "20px"}}
                                                >
                                                    {validCultures.includes(item.culture) ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: 8,
                                                                alignItems: "center"
                                                            }}
                                                        >
                                                            <div
                                                                className="auraOtherRiskScore"
                                                                style={{
                                                                    backgroundColor: getScoreInfo(riskScore).color,
                                                                }}
                                                            >
                                                                {riskScore > 0 ? riskScore : "N/D"}
                                                            </div>
                                                            <div className="auraProductivityValue">
                                                                {getProductivityRange(item.biomass_productivity, item.culture)}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <PlantStressIcon/>
                                                        </div>
                                                    )}
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            !validCultures.includes(item.culture)
                                                                ? "center"
                                                                : "flex-start",
                                                        alignItems: "center",
                                                        height: "100%",
                                                    }}
                                                >
                                                    <CultureEstimateSection culture={item.culture}/>
                                                </div>
                                                {validCultures.includes(item.culture) && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            gap: 8
                                                        }}
                                                    >
                                                        <img
                                                            src="/icons/date_range.svg"
                                                            alt="Farming Icon"
                                                            style={{
                                                                width: "40px",
                                                                height: "40px",
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                            }}
                                                        >
                                                            <Text className="plantingHarvestTitle">
                                                                Plantio e colheita:
                                                            </Text>
                                                            <Text className="plantingHarvestValue">
                                                                {`${formatDate(new Date(item.sowing_date))} - ${formatDate(new Date(item.harvest_date))}`}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    ) : (
                                        <div>Carregando dados...</div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default AuraHistoricalParameters;
