import React from "react";
import "./Aura.css";
import { Button, Divider } from "antd";
import { AutoAwesome, CloudOutlined, GrassOutlined } from "@mui/icons-material";
import dayjs from 'dayjs';

const validCultures = ["soja", "milho", "maize"];

const AuraAnalysisInsights = ({ insights, onGenerateInsight, loadingInsights, weatherReports, crop }) => {
    const isValidCulture = validCultures.includes(crop);

    return (
        
        <div className="auraAnalysisInsightsContainer">
            <div className="auraAnalysisInsightsCardTitle">
                <AutoAwesome style={{ fontSize: 32, color: "#B64CFF" }} />
                AgromAI Insights
            </div>
            <Divider style={{ margin: 0 }} />
            {isValidCulture && (
            <>
            <div className="auraAnalysisInsightsContentHeader">
                Plantio sugerido
            </div>
            <div className="auraAnalysisInsightsSection">
                <GrassOutlined style={{ fontSize: 48, color: "#00000040" }} />
                <div>
                    <div className="auraClimateAnalysisTitle">Plantio Sugerido:</div>
                    <div className="auraClimateAnalysisValue">
                        {weatherReports?.suggested_sowing_date
                            ? dayjs(weatherReports.suggested_sowing_date).format("DD/MM/YYYY")
                            : "Indisponível"}
                    </div>
                </div>
            </div>
            <div className="auraAnalysisInsightsSection">
                <CloudOutlined style={{ fontSize: 48, color: "#00000040" }} />
                <div>
                    <div className="auraClimateAnalysisTitle">Chuva Baseada na Sugestão</div>
                    <div className="auraClimateAnalysisValue">
                        {weatherReports?.suggested_rainfall
                            ? `${weatherReports.suggested_rainfall} mm`
                            : "Indisponível"}
                    </div>
                </div>
            </div>
            </>
                        )}
            <Divider style={{ margin: 0 }} />
            {insights ? (
                <div className="auraAnalysisInsightsContent">
                    {insights.split('\n').slice(1).join('\n')}
                </div>
            ) : (
                <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    paddingBottom: "32px",
                }}
            >
                <Button
                    className="auraAnalysisInsightsButton"
                    icon={<AutoAwesome style={{ fontSize: 16, verticalAlign: "center" }} />}
                    loading={loadingInsights}
                    onClick={onGenerateInsight}
                    disabled={!isValidCulture} 
                >
                    Gerar Insight
                </Button>
                
                {!isValidCulture && (
                    <div style={{ marginTop: "16px", color: "#B64CFF", fontSize: "14px" }}>
                        Sem insight para a última safra.
                    </div>
                )}
            </div>
        )}
    </div>
);
};

export default AuraAnalysisInsights;