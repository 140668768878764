// Define the styles for different polygons
const polygonStylePatterns = {
  1: {type: 'fill', color: "#ff9959", paint: {"fill-color": "#ff9959", "fill-opacity": 0.9}},
  5: {type: 'fill', color: "#af8283", paint: {"fill-color": "#af8283", "fill-opacity": 0.9}}, // area_imovel_snci
  6: {type: 'fill', color: "#f8c4b5", paint: {"fill-color": "#f8c4b5", "fill-opacity": 0.9}}, // area_pousio
  7: {type: 'fill', color: "#ef5c6c", paint: {"fill-color": "#ef5c6c", "fill-opacity": 0.9}}, // area_topo_morro
  8: {type: 'fill', color: "#4f5f14", paint: {"fill-color": "#4f5f14", "fill-opacity": 0.9}}, // banhado
  9: {type: 'fill', color: "#64e36f", paint: {"fill-color": "#64e36f", "fill-opacity": 0.9}}, // borda_chapada
  10: {type: 'fill', color: "#480d94", paint: {"fill-color": "#480d94", "fill-opacity": 0.9}}, // hidrografia
  11: {type: 'fill', color: "#c8e52a", paint: {"fill-color": "#c8e52a", "fill-opacity": 0.9}}, // manguezal
  12: {type: 'fill', color: "#0aa5b6", paint: {"fill-color": "#0aa5b6", "fill-opacity": 0.9}}, // nascente_olho_dagua
  13: {type: 'fill', color: "#fdbf6f", paint: {"fill-color": "#fdbf6f", "fill-opacity": 0.9}}, // reserva_legal
  14: {type: 'fill', color: "#27b075", paint: {"fill-color": "#27b075", "fill-opacity": 0.9}}, // restinga
  15: {type: 'fill', color: "#d9a773", paint: {"fill-color": "#d9a773", "fill-opacity": 0.9}}, // servidao_administrativa
  16: {type: 'fill', color: "#c7e75d", paint: {"fill-color": "#c7e75d", "fill-opacity": 0.9}}, // uso_restrito
  17: {type: 'fill', color: "#72af80", paint: {"fill-color": "#72af80", "fill-opacity": 0.9}}, // vegetacao_nativa
  18: {type: 'fill', color: "#b76559", paint: {"fill-color": "#b76559", "fill-opacity": 0.9}}, // vereda
  19: {type: 'fill', color: "#a4af9f", paint: {"fill-color": "#a4af9f", "fill-opacity": 0.9}}, // desmatamento_prodes
  20: {type: 'fill', color: "#77afa3", paint: {"fill-color": "#77afa3", "fill-opacity": 0.9}}, // icmbio
  21: {type: 'fill', color: "#fa5557", paint: {"fill-color": "#fa5557", "fill-opacity": 0.9}}, // ibama
  22: {type: 'fill', color: "#af6465", paint: {"fill-color": "#af6465", "fill-opacity": 0.9}}, // assentamento
  
  mapboxVisionCarArea: {
    color: '#1890FF',
    fill: {
      type: 'fill',
      paint: {
        'fill-color': '#01355D',
        'fill-opacity': 0.4,
      },
    },
    border: {
      type: 'line',
      paint: {
        'line-color': '#1890FF',
        'line-width': 3,
        'line-opacity': 1,
        'line-dasharray': [4, 4],
      },
      layout: {},
    },
  },
  mapboxVisionKmlArea: {
    type: 'fill',
    color: '#D8AECE',
    paint: {
      'fill-color': '#D8AECE',
      'fill-opacity': 1,
    },
    layout: {}
  },
  mapboxFieldAreaBorder: {
    type: 'line',
    paint: {
      'line-color': '#1890FF',
      'line-width': 3,
      'line-opacity': 1,
      'line-dasharray': [4, 4],
    },
    layout: {}
  },
  mapboxVisionEsgArea: {
    type: 'fill',
    color: '#52C41A',
    paint: {
      'fill-color': '#52C41A',
      'fill-opacity': 0.9,
    },
    layout: {}
  },
  sojaArea: {
    type: 'fill',
    color: "#E5B636",
    paint: {
      'fill-color': '#E5B636',
      'fill-opacity': 0.9,
    },
    layout: {},
  },
  milhoArea: {
    type: 'fill',
    color: "#F39345",
    paint: {
      'fill-color': '#F39345',
      'fill-opacity': 0.9,
    },
    layout: {},
  },
  arrozArea: {
    type: 'fill',
    color: "#BDBDBD",
    paint: {
      'fill-color': '#BDBDBD',
      'fill-opacity': 0.9,
    },
    layout: {},
  },
  outroArea: {
    type: 'fill',
    color: "black",
    paint: {
      'fill-color': 'black',
      'fill-opacity': 0.7,
    },
    layout: {},
  },
};

export { polygonStylePatterns };
