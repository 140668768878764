import React, { useState } from "react";
import { Marker } from "react-map-gl";

import getColor from "./GetColor";

const GetMapMarkers = ({ data, colors }) => {
  if (!data || data.length === 0) return null;

  return (
    <>
      {data.map((feature, index) => {
        const { geometry, properties } = feature;
        if (geometry.type !== "Polygon" || !properties.elevation) return null;

        const color = getColor(properties.elevation, colors);
        const markerStyle = {
          width: "8px",
          height: "8px",
          backgroundColor: color,
          opacity: 0.8,
        };

        return (
          <React.Fragment key={index}>
            <Marker
              latitude={properties.lon}
              longitude={properties.lat}
            >
              <div style={markerStyle} />
            </Marker>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default GetMapMarkers;
